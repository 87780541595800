import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, Paragph } from "@components/layout/style"
import { ListDisc, MainContainer, RightContainer, StyledHero } from "../../components/oferta.style"

const Implantologia:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
    canonicalUrl: 'https://www.mediroot.pl/oferta/implantologia-wagrowiec/',
    title: 'Implantologia Wągrowiec | Mediroot',
    description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero2.jpg"
        placeholder="blurred"
        alt="Hero image"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>

        <H1>
          Implantologia
        </H1>

        <Paragph>
          Zastąpienie braków zębowych implantami pozwoli Wam cieszyć się życiem tak, jakbyście nigdy nie mieli braków w uzębieniu. Po zakończeniu leczenia każdy pacjent może bez przeszkód gryźć, żuć, mówić i cieszyć się życiem towarzyskim nie doświadczając negatywnych uczuć towarzyszących posiadaniu braków zębowych.

        </Paragph>

        <Paragph>
          Implanty pomagają Państwu zminimalizować ilość utraconej kości, która zanika w wyniku posiadania braków zębowych. Zachowanie kości może zapobiec utracie zdrowych zębów oraz pozwoli zachować naturalne rysy twarzy. W porównaniu z innymi możliwościami leczenia braków zębowych takimi jak mosty, protezy częściowe lub całkowite, implant i jego korona zapewnia długotrwałe rozwiązania protetyczne, redukując potrzebę szlifowania sąsiednich, naturalnych zębów oraz przywracają funkcje i estetykę naturalnego uzębienia
        </Paragph>

        <H3>
          Mosty protetyczne na implantach
        </H3>

        <Paragph>
        Leczenie takie umożliwia uzupełnienie większych ilościowo braków zębowych za pomocą mostu w sytuacji, gdy brak jest naturalnych zębów filarowych. Po okresie 3 do 6 miesięcy przeprowadza się identyczne procedury jak w przypadku koron na implantach :
        </Paragph>
        <ListDisc>
          <li>
            etap pierwszy : lekarz stomatolog obnaża implanty i odkręca śruby zamykające wkręcone w implanty, następnie wkręca śruby gojące.
          </li>
          <li>
            etap drugi: po upływie około 14 dni lekarz odkręca śruby gojące i pobiera wycisk.
          </li>
          <li>
            etap trzeci: laboratoryjny Technik wykonuje most
          </li>
          <li>
            etap czwarty: Lekarz stomatolog przykręca łączniki protetyczne do implantu  oraz cementuje/przykręca  most w jamie ustnej
          </li>
        </ListDisc>

        <H3>
          Protezy ruchome na implantach
        </H3>
        <Paragph>
          Znane są różne rodzaje protez ruchomych na implantach. Na przykład całkowite i częściowe. Mocowane są różnymi systemami retencyjnymi. Najczęściej wykonuje się protezy całkowite poprawiając ich retencję i stabilizację. Proteza całkowita umieszczona na zaczepach kulowych na implantach wykazuje znacznie lepsze umocowanie i brak ruchomości niż zwykła proteza całkowita. Dzięki temu rozwiązanie jest bardzo komfortową i coraz częściej stosowaną alternatywą dla Pacjentów z bezzębiem. W przypadku protezy dolnej zwykle wystarczające jest wszczepienie 2 implantów. Etapy postępowania kliniczno-laboratoryjnego:
        </Paragph>
        <ListDisc>
          <li>
          etap pierwszy : po zabiegu wszczepienia implantów lekarz stomatolog nacina błonę śluzową i odkręca śruby zamykające znajdujące się w implantach, następnie wkręca śruby gojące
          </li>
          <li>
          etap drugi: po upływie około 14 dni lekarz odkręca śruby gojące i przykręca do implantu filary kulkowe, a następnie wykonuje wyciski
          </li>
          <li>
          etap trzeci: laboratoryjny Technik wykonuje poszczególne etapy pracy związanej z wyprodukowaniem nowej protezy (overdenture)
          </li>
          <li>
          etap czwarty: Lekarz oddaje protezę pacjentowi
          </li>
        </ListDisc>
        <ListDisc>

        </ListDisc>
      </RightContainer>
    </MainContainer>


  </Layout>);
};

export default Implantologia;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
